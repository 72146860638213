import { FlashWrapper } from '@web/atomic/legacy/obj.flash-wrapper/flash-wrapper.component';
import React, { ReactElement } from 'react';

interface CleanLayoutProps {
  children: ReactElement | ReactElement[];
}

/**
 * This layout should be used when you don't want to display a header and/or a footer on the site
 * @param param0
 */
const CleanLayout: React.FunctionComponent<CleanLayoutProps> = ({ children }) => {
  return (
    <>
      <FlashWrapper />
      {children}
    </>
  );
};

export default CleanLayout;
