import { SEO } from '@lp-root/src/components/legacy/mol.seo/seo.component';
import OrderSuccess from '@lp-root/src/modules/order-success/order-success.component';
import { PageProps } from '@lp-src/utils/local-types';
import { getCanonicalUrl } from '@web/utils/url';
import { graphql } from 'gatsby';
import * as React from 'react';
import CleanLayout from '@lp-root/src/components/org.layout/clean-layout.component';
import { useQueryParams } from '@web/atomic/obj.custom-hooks/query-params';
import { Body } from '@web/atomic';
import WhatsappFAB from '@web/atomic/atm.whatsapp-fab/whatsapp-fab.component';

interface InstrucoesPageQueryParams {
  dl: string;
}

const InstrucoesPage: React.FunctionComponent<PageProps> = (props) => {
  const queryParams = useQueryParams<InstrucoesPageQueryParams>();
  const socialMedia = {
    canonicalUrl: getCanonicalUrl(props.data.site.siteMetadata.siteUrl, props.location.pathname),
    title: 'Prazer, Sônia',
    description: 'Conheça a Sônia, nossa coach digital que te acompanhará durante todo o programa',
  };

  return (
    <CleanLayout>
      <SEO socialMedia={socialMedia} />
      <OrderSuccess
        headline={'Conheça o programa que vai transformar a sua relação com o sono e saúde mental.'}
        share={{
          url: socialMedia.canonicalUrl,
          title: socialMedia.title,
          text: socialMedia.description,
        }}
      >
        {queryParams.dl ? (
          <Body>Você possui um código que te dá direito a benefícios no programa. Utilize-o dentro do app para ativá-lo.</Body>
        ) : null}
      </OrderSuccess>
      <WhatsappFAB kind="support" />
    </CleanLayout>
  );
};

export default InstrucoesPage;

export const query = graphql`
  query InstrucoesQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
